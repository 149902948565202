var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticStyle:{"max-width":"100vw"}},[_c('v-text-field',{staticClass:"mx-12",attrs:{"append-icon":"mdi-magnify","label":"Buscar","single-line":"","hide-details":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.searchNow=!_vm.searchNow}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(_vm.showTable)?_c('v-data-table',{staticClass:"elevation-0 px-6 pb-4 pt-10",attrs:{"height":"600","fixed-header":"","footer-props":{'items-per-page-options':[15, 30, 50, 500, 999]},"headers":_vm.headers,"items":_vm.companies,"options":_vm.options,"server-items-length":_vm.totalCompanies,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.permissions('editClients')==true || _vm.permissions('deleteClients')==true)?_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{staticStyle:{"font-size":"13px"}},[_c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.permissions('editClients')),expression:"permissions('editClients')"}],on:{"click":function($event){return _vm.editItem(item.editedItem)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-pencil ")]),_vm._v(" Editar ")],1),_c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.permissions('deleteClients')),expression:"permissions('deleteClients')"}],on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-delete ")]),_vm._v(" Eliminar ")],1)],1)],1):_vm._e()]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.status!=undefined)?_c('v-chip',_vm._g(_vm._b({staticClass:"pa-2",style:({'background':'linear-gradient(0deg, rgb(255 255 255 / 85%), rgb(255 255 255 / 85%)) rgb(0, 198, 255)', 'background-color':item.status.color, 'color':item.status.color}),attrs:{"small":""}},'v-chip',attrs,false),on),[_vm._v(_vm._s(item.status.name))]):_vm._e()]}}],null,true)},[_c('v-list',_vm._l((_vm.statusesLists),function(status,index){return _c('v-list-item',{key:index},[_c('v-list-item-content',{staticClass:"pa-0"},[(status!=undefined)?_c('v-list-item-title',[_c('v-btn',{attrs:{"small":"","text":""},on:{"click":function($event){return _vm.changeStatus(item.id, status.id)}}},[_vm._v(_vm._s(status.name))])],1):_vm._e()],1)],1)}),1)],1)]}},{key:"item.view",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"gato",attrs:{"icon":"","to":{ path: '/clients/brand/'+ item.id}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-eye")])],1)]}},{key:"item.agency",fn:function(ref){
var item = ref.item;
return [(item.agency!=undefined)?_c('span',[_vm._v(_vm._s(item.agency.name.toUpperCase()))]):_vm._e()]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.created_at.slice(0, 10))+" ")]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.updated_at.slice(0, 10))+" ")]}},{key:"no-data",fn:function(){return [_vm._v(" No existen registros de clientes aún ")]},proxy:true}],null,true)}):_vm._e(),_c('v-dialog',{attrs:{"max-width":"800px"},model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}},[_c('edit',{attrs:{"editedCompany":_vm.company},on:{"closeDialogEditCompany":_vm.closeDialogEditCompany}})],1),_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.detailDialog),callback:function ($$v) {_vm.detailDialog=$$v},expression:"detailDialog"}},[_c('detail',{attrs:{"company":_vm.company},on:{"closeDialogDetailCompany":_vm.closeDialogDetailCompany}})],1),_c('div',{staticClass:"text-center"},[_c('v-bottom-sheet',{attrs:{"inset":""},model:{value:(_vm.sheet),callback:function ($$v) {_vm.sheet=$$v},expression:"sheet"}},[_c('v-sheet',{staticClass:"text-center",attrs:{"height":"150px"}},[_c('div',{staticClass:"pt-6"},[_vm._v(" ¿Seguro que deseas borrar esta empresa? ")]),_c('v-btn',{staticClass:"mt-4",attrs:{"text":"","color":"error"},on:{"click":function($event){return _vm.deleteCompany()}}},[_vm._v(" Eliminar ")]),_c('v-btn',{staticClass:"mt-4",attrs:{"text":"","color":"grey"},on:{"click":function($event){return _vm.cancel()}}},[_vm._v(" Cancelar ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }